import { PagedListOfMobilityClassDTO, DictionariesClient } from "@/api/nswag.generated";
export default class DictionaryService {
    dictionariesClient = new DictionariesClient();
    async getMobilityClasses(pageSize, pageNumber, orderBy, descending, searchQuery) {
        const mobilityClasses = await this.dictionariesClient.mobilityClassesList(pageSize, pageNumber, orderBy, descending, searchQuery, null);
        if (mobilityClasses && mobilityClasses.mobilityClasses) {
            return mobilityClasses.mobilityClasses;
        }
        return new PagedListOfMobilityClassDTO();
    }
}
