import { DeviceDepartmentFilterFields, PagedListOfDeviceDTO, } from "../../api/nswag.generated";
import { DeviceClient } from "@/api/nswag.generated";
export default class DeviceService {
    deviceClient = new DeviceClient();
    async getAsync(pageSize, pageNumber, orderBy, descending, searchQuery, filter) {
        if (filter == null) {
            filter = new DeviceDepartmentFilterFields();
        }
        const devices = await this.deviceClient.list(filter, pageSize, pageNumber, orderBy, descending, searchQuery);
        if (devices && devices.devices) {
            return devices.devices;
        }
        return new PagedListOfDeviceDTO();
    }
    async getById(id) {
        return await this.deviceClient.getDeviceById(id);
    }
    async createAsync(command) {
        return await this.deviceClient.create(command);
    }
    async updateDevice(id, command) {
        return await this.deviceClient.put(id, command);
    }
}
