import { PagedListOfDeviceDepartmentDTO, DeviceDepartmentsClient, DeviceFilterFields, } from "@/api/nswag.generated";
export default class DeviceDepartmentsService {
    deviceDepartmentsClient = new DeviceDepartmentsClient();
    async getDeviceDepartmentsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, filter) {
        if (filter == null) {
            filter = new DeviceFilterFields();
        }
        const deviceDep = await this.deviceDepartmentsClient.list(filter, pageSize, pageNumber, orderBy, descending, searchQuery);
        if (deviceDep && deviceDep.deviceDepartments) {
            return deviceDep.deviceDepartments;
        }
        return new PagedListOfDeviceDepartmentDTO();
    }
    async getOrganizationDeviceDepartmentsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, organizationId, filter) {
        if (filter == null) {
            filter = new DeviceFilterFields();
        }
        const deviceDep = await this.deviceDepartmentsClient.listOrganizationDeviceDepartments(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null, organizationId);
        if (deviceDep && deviceDep.deviceDepartments) {
            return deviceDep.deviceDepartments;
        }
        return new PagedListOfDeviceDepartmentDTO();
    }
    async createAsync(command) {
        return await this.deviceDepartmentsClient.create(command);
    }
    async getById(id) {
        return await this.deviceDepartmentsClient.getById(id);
    }
    async update(command) {
        return await this.deviceDepartmentsClient.put(command.id, command);
    }
}
